import React from 'react';
import Layout from '../../components/Layout/Layout';
import Icon from '../../assets/img/icons/ic-square-check.svg';

import styles from './index.module.scss';

const Index = () => (
  <Layout mainClasses="section-padded-wrapper flex-grow-1">
    <div className="info-page-container">
      <div className={styles.textual}>
        <div className={`${styles.title} t-700`}>
          What we do?
        </div>
        <div className="body-16-reg">
          <div className={styles.row}>
            <Icon className={styles.rowIcon} />
            <div className={styles.rowText}>
              We research and rate properties & activities and publish a comprehensive review
            </div>
          </div>
          <div className={styles.row}>
            <Icon className={styles.rowIcon} />
            <div className={styles.rowText}>
              Our proprietary algorithm values and ranks each option
            </div>
          </div>
          <div className={styles.row}>
            <Icon className={styles.rowIcon} />
            <div className={styles.rowText}>
              The valuation takes into account refundability and extras such as whether breakfast or lunch is included
            </div>
          </div>
        </div>
      </div>
      <div className={styles.textual}>
        <div className={`${styles.title} t-700`}>
          What is different about us?
        </div>
        <div className="body-16-reg">
          <div className={styles.row}>
            <Icon className={styles.rowIcon} />
            <div className={styles.rowText}>
              We enable users to make the best choice based on their personal criteria
            </div>

          </div>
          <div className={styles.row}>
            <Icon className={styles.rowIcon} />
            <div className={styles.rowText}>
              Environmental & Social aspects are rated and part of the valuation
            </div>
          </div>
          <div className={styles.row}>
            <Icon className={styles.rowIcon} />
            <div className={styles.rowText}>
              No misleading users by placing sponsored offers at the top of the rankings
            </div>
          </div>
        </div>
      </div>
      <div className={styles.textual}>
        <div className={`${styles.title} t-700`}>
          Our vision
        </div>
        <div className="body-16-reg">
          We aim to bring simplicity, fairness and values into every consumer’s choices
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
